<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status" :options="Config.mr.StatusOptions" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>

            <template
              #cell(mfaj_minutes_tolerance) = "data"
            >
              {{data.value}} menit
            </template>

            <template #cell(mfaj_color)="data">
                <div :style="`background-color:${data.value}`" class="d-inline-block w-25 p-2"></div>
            </template>
            
            <template
              #cell(mfaj_flag_terlambat) = "data"
            >
              {{data.value == "Y" ? "Ya": "Tidak"}}
            </template>
            
            <template
              #cell(mfaj_is_active) = "data"
            >
              <b-badge
                v-if="data.value=='Y'"
                variant="success"
              >Aktif</b-badge>
              <b-badge
                v-else
                variant="danger"
              >Nonaktif</b-badge>
            </template>
            <template
              #cell(action)="data"
            >
              <div class="list-icons">
                
                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('name')"
                  @click="doChangeAturTerlambat(data.index, data.item)"
                 v-b-tooltip.hover title="Atur Flagging Mulai Terlambat"><i
                    class="icon-notification2"></i></a>
                <router-link class="list-icons-item"
                  v-if="moduleRole('edit')"
                  :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                 v-b-tooltip.hover title="View"><i
                    class="icon-pencil5"></i></router-link>
                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('delete')"
                  @click="doDelete(data.index, data.item)"
                 v-b-tooltip.hover title="Delete"><i
                    class="icon-bin"></i></a>
                <a href="javascript:;" class="list-icons-item"
                  v-if="moduleRole('changeStatus')"
                  @click="doChangeStatus(data.index, data.item)"
                 v-b-tooltip.hover title="Change Status"><i
                    class="icon-cog6"></i></a>
              </div>
              <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'mfaj_id',
      statusKey:'mfaj_is_active',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'mfaj_name',
          label: 'Nama',
        },
        {
          key: 'mfaj_minutes_tolerance',
          label: 'Toleransi Keterlambatan',
        },
        {
          key: 'mfaj_color',
          label: 'Warna',
        },
        {
          key: 'mfaj_flag_terlambat',
          label: 'Flag Terlambat',
        },
        {
          key: 'mfaj_is_active',
          label: 'Status',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    doChangeAturTerlambat(k,v){
      this.$swal({
        title: `Ubah Flagging Terlambat ?`,
        text : `Data yang lebih dari waktu toleransi lebih dari ${v.mfaj_minutes_tolerance} menit akan dilabeli waktu terlambat`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya!`,
        cancelButtonText:  `Tidak, kembali`,        
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.loadingOverlay = true
      
          Gen.apiRest("/do/"+this.modulePage,{data:{type:'change-terlambat',id:v.mfaj_id,waktu_terlambat: v.mfaj_minutes_tolerance}}, "POST")
          .then((resp = {})=>{
            this.apiGet()
            this.$swal({
              title: resp.data.message || 'Data Berhasil Diupdate!',
              icon: 'success',
            })
          })
          .catch(err=>{ 
            this.loadingOverlay = false 
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.title = err.response.data.title
                err.message = err.response.data.message
                err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })        
        }
      })
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>