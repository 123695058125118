<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>

          <b-col lg="5">
            <b-form-group>
              <label>Nama<span class="text-danger mr5">*</span></label>
              <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.mfaj_name"/>
              <VValidate 
                name="Nama" 
                v-model="row.mfaj_name" 
                :rules="toValidate(mrValidation.mfaj_name)"
              />
            </b-form-group>
          </b-col>
          
          <b-col lg="5">
            <b-form-group>
              <label>Toleransi Keterlambatan<span class="text-danger mr5">*</span></label>
              <div class="input-group">
                <b-form-input :formatter="$parent.numberOnly" placeholder="Toleransi" type="text" class="form-control" v-model="row.mfaj_minutes_tolerance"/>
                <div class="input-group-prepend">
                  <span class="input-group-text">Menit</span>
                </div>
              </div>
              <VValidate 
                name="Toleransi Keterlambatan" 
                v-model="row.mfaj_minutes_tolerance" 
                :rules="toValidate(mrValidation.mfaj_minutes_tolerance)"
              />
            </b-form-group>
          </b-col>

          
          <b-col lg="5">
            <b-form-group>
              <label>Warna<span class="text-danger mr5">*</span></label>
              <b-form-input type="color" class="form-control" v-model="row.mfaj_color" placeholder="e.g 0001"/>
            
              <VValidate 
                name="Warna" 
                v-model="row.mfaj_color" 
                :rules="toValidate(mrValidation.mfaj_color)"
              />
            </b-form-group>
          </b-col>

          <!--
          <b-col lg="5" class="ml-4 mt-4">
            <b-form-group>
              <b-form-checkbox
                value="Y"
                unchecked-value="N"
                class="display-c"
                id="checkbox-group-2 display-c"
                v-model="row.mfaj_flag_terlambat"
                name="flavour-2"
              >
                Flagging terlambat dimulai dari sini
              </b-form-checkbox>
              <small class="text-info d-block">*Data dengan waktu toleransi lebih dari ini akan di hitung waktu terlambat</small>
            </b-form-group>
          </b-col>
          -->
          
          <b-col lg="6">
            <b-form-group class="mt-3">
              <label>Status <span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="Config.mr.StatusOptions"
                v-model="row[$parent.statusKey]"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods: {
    toValidate(val){
      return {...val}
    }
  },
}
</script>